import { FC } from 'react'
import { PublishedFormSlide } from '../../../orval/loovPublic'
import FormSlidePlayerPresentation from './FormSlidePlayerPresentation'

export type FormSlidePlayerProps = {
  publishedFormSlide: PublishedFormSlide
  onMount: () => void
  onSubmit: (data: Record<string, string | string[]>) => void
}
const FormSlidePlayer: FC<FormSlidePlayerProps> = (props) => {
  return <FormSlidePlayerPresentation {...props} />
}

export default FormSlidePlayer
