import { MobileDisplayType } from '../orval/loov'

export function ensure<T>(arg: T, error?: string | Error): NonNullable<T> {
  if (arg === null || arg === undefined) {
    if (error instanceof Error) {
      throw error
    }

    throw Error(error ?? 'Please ensure an argument is neither undefined nor null.')
  }

  return arg
}

export function assertNever(_: never): never {
  throw Error('Exhaustiveness Error')
}

export function downloadBlob(blob: Blob, filename: string): void {
  const anchor = document.createElement('a')
  anchor.href = URL.createObjectURL(blob)
  anchor.download = filename
  anchor.click()
  URL.revokeObjectURL(anchor.href)
}

export async function noop() {
  // do nothing
}

export async function wait(milliseconds: number) {
  await new Promise((r) => setTimeout(r, milliseconds))
}

export function isMobileDisplayTypeSide(
  mobileDisplayType: MobileDisplayType,
): mobileDisplayType is 'SIDE_RIGHT' | 'SIDE_LEFT' {
  return [MobileDisplayType.SIDE_RIGHT, MobileDisplayType.SIDE_LEFT].includes(mobileDisplayType)
}

export function isMobileDisplayTypeBottom(
  mobileDisplayType: MobileDisplayType,
): mobileDisplayType is 'BOTTOM_RIGHT' | 'BOTTOM_LEFT' {
  return [MobileDisplayType.BOTTOM_RIGHT, MobileDisplayType.BOTTOM_LEFT].includes(mobileDisplayType)
}

export function isMobileDisplayTypeCustom(
  mobileDisplayType: MobileDisplayType,
): mobileDisplayType is
  | 'CUSTOM_TOP_LEFT'
  | 'CUSTOM_TOP_RIGHT'
  | 'CUSTOM_SIDE_LEFT'
  | 'CUSTOM_SIDE_RIGHT'
  | 'CUSTOM_BOTTOM_LEFT'
  | 'CUSTOM_BOTTOM_RIGHT' {
  return [
    MobileDisplayType.CUSTOM_TOP_LEFT,
    MobileDisplayType.CUSTOM_TOP_RIGHT,
    MobileDisplayType.CUSTOM_SIDE_LEFT,
    MobileDisplayType.CUSTOM_SIDE_RIGHT,
    MobileDisplayType.CUSTOM_BOTTOM_LEFT,
    MobileDisplayType.CUSTOM_BOTTOM_RIGHT,
  ].includes(mobileDisplayType)
}
