import { CloseButton, Grid, Icon, VStack } from '@chakra-ui/react'
import { FC } from 'react'
import { BsPlayCircle } from 'react-icons/bs'
import { assertNever } from '../../../commons/utils'
import Body from '../../elements/typography/Body'

type EmbeddedMobileSideFloatProps = {
  position: 'right' | 'left'
  onClick: () => void
  onClose: () => void
}
const EmbeddedMobileSideFloat: FC<EmbeddedMobileSideFloatProps> = (props) => {
  return (
    <Grid
      pos="fixed"
      top="50%"
      {...(props.position === 'right'
        ? { right: 0, roundedLeft: 'lg' }
        : props.position === 'left'
        ? { left: 0, roundedRight: 'lg' }
        : assertNever(props.position))}
      transform="translateY(-50%)"
      bg="#F00"
      color="white"
      pt="6px"
      px="8px"
      pb="12px"
      placeItems="center"
      onClick={() => props.onClick()}
    >
      <VStack>
        <Icon as={BsPlayCircle} fontSize="16px" />
        <Body w="14px" fontSize="14px" fontWeight="bold" lineHeight="120%" letterSpacing="0.56px">
          動画公開中
        </Body>
      </VStack>

      <CloseButton
        pos="absolute"
        bottom={0}
        left="50%"
        transform="translate(-50%, 50%)"
        boxSize="16px"
        rounded="full"
        bg="#FAE1E1"
        fontSize="8px"
        color="#F00"
        onClick={(e) => (e.stopPropagation(), props.onClose())}
      />
    </Grid>
  )
}

export default EmbeddedMobileSideFloat
