/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * loov-api
 */
import {
  default as createLeadMutator,
  default as createLeadSessionEventMutator,
  default as createLeadSessionMutator,
  default as getEmbedScriptMutator,
  default as getLatestPublishedScenarioMutator,
  default as getLeadMutator,
  default as getScenarioMobileThumbnailMutator,
  default as mergeLeadMutator,
  default as setLeadProfileToLeadMutator,
} from './loovClient'
export type GetLatestPublishedScenarioParams = {
  scenarioId: string
}

export type GetLead308 = {
  message: string
}

export type WithUrlScenarioMobileThumbnailAllOf = {
  url: Url
}

export interface ScenarioMobileThumbnail {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUrlScenarioMobileThumbnail = ScenarioMobileThumbnail & WithUrlScenarioMobileThumbnailAllOf

/**
 * @nullable
 */
export type PublishedScenarioDetailAllOfScenarioThumbnail = WithUrlScenarioThumbnail | null

/**
 * @nullable
 */
export type PublishedScenarioDetailAllOfScenarioLogo = WithUrlScenarioLogo | null

export type ComplexPublishedSlide = PublishedVideoSlide | PublishedSelectionSlide | PublishedFormSlide

export type PublishedScenarioDetailAllOf = {
  publishedSlides: ComplexPublishedSlide[]
  /** @nullable */
  scenarioLogo: PublishedScenarioDetailAllOfScenarioLogo
  /** @nullable */
  scenarioThumbnail: PublishedScenarioDetailAllOfScenarioThumbnail
}

export type PublishedScenarioDetail = PublishedScenario & PublishedScenarioDetailAllOf

/**
 */
export type PublishedFormSlideAllOfType = (typeof PublishedFormSlideAllOfType)[keyof typeof PublishedFormSlideAllOfType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedFormSlideAllOfType = {
  FORM: 'FORM',
} as const

/**
 * @nullable
 */
export type PublishedFormSlideAllOfPublishedVideoSlideContent =
  | (typeof PublishedFormSlideAllOfPublishedVideoSlideContent)[keyof typeof PublishedFormSlideAllOfPublishedVideoSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedFormSlideAllOfPublishedVideoSlideContent = {} as const

/**
 * @nullable
 */
export type PublishedFormSlideAllOfPublishedSelectionSlideContent =
  | (typeof PublishedFormSlideAllOfPublishedSelectionSlideContent)[keyof typeof PublishedFormSlideAllOfPublishedSelectionSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedFormSlideAllOfPublishedSelectionSlideContent = {} as const

export type PublishedFormSlideAllOf = {
  publishedFormSlideContent: JoinedPublishedFormSlideContent
  /** @nullable */
  publishedSelectionSlideContent: PublishedFormSlideAllOfPublishedSelectionSlideContent
  /** @nullable */
  publishedVideoSlideContent: PublishedFormSlideAllOfPublishedVideoSlideContent
  /** */
  type: PublishedFormSlideAllOfType
}

export type PublishedFormSlide = PublishedSlide & PublishedFormSlideAllOf

export type JoinedPublishedFormSlideContentAllOf = {
  /** @nullable */
  formThumbnail: JoinedPublishedFormSlideContentAllOfFormThumbnail
  publishedFormInputs: JoinedPublishedFormInput[]
}

export type JoinedPublishedFormSlideContent = PublishedFormSlideContent & JoinedPublishedFormSlideContentAllOf

export type WithUrlFormThumbnailAllOf = {
  url: Url
}

export interface FormThumbnail {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUrlFormThumbnail = FormThumbnail & WithUrlFormThumbnailAllOf

/**
 * @nullable
 */
export type JoinedPublishedFormSlideContentAllOfFormThumbnail = WithUrlFormThumbnail | null

export interface PublishedFormInputOption {
  createdAt: string
  formInputOptionId: Cuid
  id: Cuid
  label: string
  publishedFormInputId: Cuid
  updatedAt: string
  value: string
}

export type JoinedPublishedFormInputAllOf = {
  options: PublishedFormInputOption[]
}

export type JoinedPublishedFormInput = PublishedFormInput & JoinedPublishedFormInputAllOf

export type HubspotCreateContactProperty =
  (typeof HubspotCreateContactProperty)[keyof typeof HubspotCreateContactProperty]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HubspotCreateContactProperty = {
  company: 'company',
  email: 'email',
  firstname: 'firstname',
  lastname: 'lastname',
  numemployees: 'numemployees',
  phone: 'phone',
  state: 'state',
  city: 'city',
  jobtitle: 'jobtitle',
} as const

/**
 * @nullable
 */
export type PublishedFormInputHubspotContactProperty = HubspotCreateContactProperty | null

export type SalesforceCreateLeadProperty =
  (typeof SalesforceCreateLeadProperty)[keyof typeof SalesforceCreateLeadProperty]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesforceCreateLeadProperty = {
  Company: 'Company',
  Email: 'Email',
  FirstName: 'FirstName',
  LastName: 'LastName',
  NumberOfEmployees: 'NumberOfEmployees',
  Phone: 'Phone',
  State: 'State',
  City: 'City',
  Title: 'Title',
} as const

/**
 * @nullable
 */
export type PublishedFormInputSalesforceLeadProperty = SalesforceCreateLeadProperty | null

export type FormInputType = (typeof FormInputType)[keyof typeof FormInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormInputType = {
  TEXT: 'TEXT',
  EMAIL: 'EMAIL',
  SELECT: 'SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
} as const

export interface PublishedFormInput {
  createdAt: string
  formInputId: Cuid
  /** @nullable */
  hubspotContactProperty: PublishedFormInputHubspotContactProperty
  id: Cuid
  name: string
  placeholder: string
  position: number
  publishedFormSlideContentId: Cuid
  required: boolean
  /** @nullable */
  salesforceLeadProperty: PublishedFormInputSalesforceLeadProperty
  type: FormInputType
  updatedAt: string
}

/**
 * @nullable
 */
export type PublishedFormSlideContentNextUrl = Url | null

/**
 * @nullable
 */
export type PublishedFormSlideContentNextPublishedSlideId = Cuid | null

/**
 * @nullable
 */
export type PublishedFormSlideContentFormThumbnailId = Cuid | null

export type Email = string

export type FormDesign = (typeof FormDesign)[keyof typeof FormDesign]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormDesign = {
  DEFAULT: 'DEFAULT',
  WITH_THUMBNAIL: 'WITH_THUMBNAIL',
} as const

export interface PublishedFormSlideContent {
  buttonText: string
  createdAt: string
  description: string
  descriptionUnderThumbnail: string
  design: FormDesign
  /** @nullable */
  formThumbnailId: PublishedFormSlideContentFormThumbnailId
  id: Cuid
  /** @nullable */
  nextPublishedSlideId: PublishedFormSlideContentNextPublishedSlideId
  /** @nullable */
  nextUrl: PublishedFormSlideContentNextUrl
  notificationEmail: Email
  notifyWithFormAnswers: boolean
  privacyPolicyCheckboxText: string
  privacyPolicyText: string
  privacyPolicyUrl: Url
  publishedSlideId: Cuid
  thanksMailBcc: string
  thanksMailBodyConclusion: string
  thanksMailBodyIntroduction: string
  thanksMailDisplayName: string
  thanksMailEnabled: boolean
  thanksMailSubject: string
  updatedAt: string
}

/**
 */
export type PublishedSelectionSlideAllOfType =
  (typeof PublishedSelectionSlideAllOfType)[keyof typeof PublishedSelectionSlideAllOfType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedSelectionSlideAllOfType = {
  SELECTION: 'SELECTION',
} as const

/**
 * @nullable
 */
export type PublishedSelectionSlideAllOfPublishedVideoSlideContent =
  | (typeof PublishedSelectionSlideAllOfPublishedVideoSlideContent)[keyof typeof PublishedSelectionSlideAllOfPublishedVideoSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedSelectionSlideAllOfPublishedVideoSlideContent = {} as const

/**
 * @nullable
 */
export type PublishedSelectionSlideAllOfPublishedFormSlideContent =
  | (typeof PublishedSelectionSlideAllOfPublishedFormSlideContent)[keyof typeof PublishedSelectionSlideAllOfPublishedFormSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedSelectionSlideAllOfPublishedFormSlideContent = {} as const

export type PublishedSelectionSlideAllOf = {
  /** @nullable */
  publishedFormSlideContent: PublishedSelectionSlideAllOfPublishedFormSlideContent
  publishedSelectionSlideContent: JoinedPublishedSelectionSlideContent
  /** @nullable */
  publishedVideoSlideContent: PublishedSelectionSlideAllOfPublishedVideoSlideContent
  /** */
  type: PublishedSelectionSlideAllOfType
}

export type PublishedSelectionSlide = PublishedSlide & PublishedSelectionSlideAllOf

/**
 * @nullable
 */
export type PublishedSelectionOptionNextUrl = Url | null

/**
 * @nullable
 */
export type PublishedSelectionOptionNextPublishedSlideId = Cuid | null

export interface PublishedSelectionOption {
  createdAt: string
  id: Cuid
  /** @nullable */
  nextPublishedSlideId: PublishedSelectionOptionNextPublishedSlideId
  /** @nullable */
  nextUrl: PublishedSelectionOptionNextUrl
  publishedSelectionSlideContentId: Cuid
  selectionOptionId: Cuid
  updatedAt: string
  value: string
}

export type JoinedPublishedSelectionSlideContentAllOf = {
  publishedSelectionOptions: PublishedSelectionOption[]
}

export type SelectionSlideDirection = (typeof SelectionSlideDirection)[keyof typeof SelectionSlideDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectionSlideDirection = {
  COLUMN: 'COLUMN',
  ROW: 'ROW',
} as const

export interface PublishedSelectionSlideContent {
  createdAt: string
  direction: SelectionSlideDirection
  id: Cuid
  publishedSlideId: Cuid
  text: string
  updatedAt: string
}

export type JoinedPublishedSelectionSlideContent = PublishedSelectionSlideContent &
  JoinedPublishedSelectionSlideContentAllOf

export type PublishedVideoSlide = PublishedSlide & PublishedVideoSlideAllOf

/**
 */
export type PublishedVideoSlideAllOfType =
  (typeof PublishedVideoSlideAllOfType)[keyof typeof PublishedVideoSlideAllOfType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedVideoSlideAllOfType = {
  VIDEO: 'VIDEO',
} as const

/**
 * @nullable
 */
export type PublishedVideoSlideAllOfPublishedSelectionSlideContent =
  | (typeof PublishedVideoSlideAllOfPublishedSelectionSlideContent)[keyof typeof PublishedVideoSlideAllOfPublishedSelectionSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedVideoSlideAllOfPublishedSelectionSlideContent = {} as const

/**
 * @nullable
 */
export type PublishedVideoSlideAllOfPublishedFormSlideContent =
  | (typeof PublishedVideoSlideAllOfPublishedFormSlideContent)[keyof typeof PublishedVideoSlideAllOfPublishedFormSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedVideoSlideAllOfPublishedFormSlideContent = {} as const

export type VideoWithUrl = WithThumbnailUrlAvailableVideo | UnAvailableVideo

/**
 * @nullable
 */
export type JoinedPublishedVideoSlideContentAllOfVideo = VideoWithUrl | null

export type JoinedPublishedVideoSlideContentAllOf = {
  /** @nullable */
  video: JoinedPublishedVideoSlideContentAllOfVideo
}

export type JoinedPublishedVideoSlideContent = PublishedVideoSlideContent & JoinedPublishedVideoSlideContentAllOf

export type PublishedVideoSlideAllOf = {
  /** @nullable */
  publishedFormSlideContent: PublishedVideoSlideAllOfPublishedFormSlideContent
  /** @nullable */
  publishedSelectionSlideContent: PublishedVideoSlideAllOfPublishedSelectionSlideContent
  publishedVideoSlideContent: JoinedPublishedVideoSlideContent
  /** */
  type: PublishedVideoSlideAllOfType
}

/**
 * @nullable
 */
export type UnAvailableVideoAllOfDuration =
  | (typeof UnAvailableVideoAllOfDuration)[keyof typeof UnAvailableVideoAllOfDuration]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnAvailableVideoAllOfDuration = {} as const

/**
 * Exclude from T those types that are assignable to U
 */
export type ExcludeVideoStatusAVAILABLE = (typeof ExcludeVideoStatusAVAILABLE)[keyof typeof ExcludeVideoStatusAVAILABLE]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExcludeVideoStatusAVAILABLE = {
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  ERROR: 'ERROR',
} as const

export type UnAvailableVideoAllOf = {
  /** @nullable */
  duration: UnAvailableVideoAllOfDuration
  status: ExcludeVideoStatusAVAILABLE
}

export type UnAvailableVideo = VideoBase & UnAvailableVideoAllOf

export type WithThumbnailUrlAvailableVideoAllOf = {
  animatedThumbnailUrl: Url
  thumbnailUrl: Url
}

export type WithThumbnailUrlAvailableVideo = WithUrlAvailableVideo & WithThumbnailUrlAvailableVideoAllOf

export type WithUrlAvailableVideoAllOf = {
  url: Url
}

export type WithUrlAvailableVideo = AvailableVideo & WithUrlAvailableVideoAllOf

/**
 */
export type AvailableVideoAllOfStatus = (typeof AvailableVideoAllOfStatus)[keyof typeof AvailableVideoAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailableVideoAllOfStatus = {
  AVAILABLE: 'AVAILABLE',
} as const

export type AvailableVideoAllOf = {
  duration: number
  /** */
  status: AvailableVideoAllOfStatus
}

export type VideoStatus = (typeof VideoStatus)[keyof typeof VideoStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoStatus = {
  AVAILABLE: 'AVAILABLE',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  ERROR: 'ERROR',
} as const

export interface VideoBase {
  createdAt: string
  id: Cuid
  name: string
  status: VideoStatus
  tenantId: Cuid
  updatedAt: string
}

export type AvailableVideo = VideoBase & AvailableVideoAllOf

/**
 * @nullable
 */
export type PublishedVideoSlideContentVideoId = Cuid | null

/**
 * @nullable
 */
export type PublishedVideoSlideContentNextUrl = Url | null

/**
 * @nullable
 */
export type PublishedVideoSlideContentNextPublishedSlideId = Cuid | null

export interface PublishedVideoSlideContent {
  createdAt: string
  id: Cuid
  /** @nullable */
  nextPublishedSlideId: PublishedVideoSlideContentNextPublishedSlideId
  /** @nullable */
  nextUrl: PublishedVideoSlideContentNextUrl
  publishedSlideId: Cuid
  updatedAt: string
  /** @nullable */
  videoId: PublishedVideoSlideContentVideoId
}

export type SlideType = (typeof SlideType)[keyof typeof SlideType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlideType = {
  VIDEO: 'VIDEO',
  SELECTION: 'SELECTION',
  FORM: 'FORM',
} as const

export interface PublishedSlide {
  createdAt: string
  id: Cuid
  name: string
  position: number
  publishedScenarioId: Cuid
  slideId: Cuid
  type: SlideType
  updatedAt: string
}

export type WithUrlScenarioThumbnailAllOf = {
  url: Url
}

export interface ScenarioThumbnail {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUrlScenarioThumbnail = ScenarioThumbnail & WithUrlScenarioThumbnailAllOf

export type WithUrlScenarioLogoAllOf = {
  url: Url
}

export interface ScenarioLogo {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUrlScenarioLogo = ScenarioLogo & WithUrlScenarioLogoAllOf

/**
 * @nullable
 */
export type PublishedScenarioScenarioThumbnailId = Cuid | null

/**
 * @nullable
 */
export type PublishedScenarioScenarioLogoId = Cuid | null

export type Url = string

export type ScenarioType = (typeof ScenarioType)[keyof typeof ScenarioType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScenarioType = {
  PAGE: 'PAGE',
  EMBEDDED: 'EMBEDDED',
} as const

export interface PublishedScenario {
  createdAt: string
  ctaColor: string
  ctaFontSize: number
  ctaText: string
  ctaUrl: Url
  googleAnalyticsMeasurementId: string
  id: Cuid
  introductionCompanyName: string
  introductionDescription: string
  introductionTitle: string
  isGoBackButtonEnabled: boolean
  modalDescription: string
  name: string
  scenarioId: Cuid
  /** @nullable */
  scenarioLogoId: PublishedScenarioScenarioLogoId
  /** @nullable */
  scenarioThumbnailId: PublishedScenarioScenarioThumbnailId
  tenantId: Cuid
  type: ScenarioType
  updatedAt: string
}

export type CreateLeadSessionInputAllOf = {
  notify?: boolean
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickLeadSessionLeadIdOrPublishedScenarioId {
  leadId: Cuid
  publishedScenarioId: Cuid
}

export type CreateLeadSessionInput = PickLeadSessionLeadIdOrPublishedScenarioId & CreateLeadSessionInputAllOf

export interface LeadSession {
  createdAt: string
  id: Cuid
  isRewatch: boolean
  leadId: Cuid
  publishedScenarioId: Cuid
  updatedAt: string
}

/**
 */
export type CreateFormAnswerEventInputType =
  (typeof CreateFormAnswerEventInputType)[keyof typeof CreateFormAnswerEventInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateFormAnswerEventInputType = {
  FORM_ANSWER: 'FORM_ANSWER',
} as const

export interface CreateFormAnswerEventInput {
  formValues: FormValue[]
  leadSessionId: string
  publishedSlideId: string
  /** */
  type: CreateFormAnswerEventInputType
}

export type FormValueAllOfTwoAnyOfTwo = {
  publishedFormInputOptionIds: string[]
}

export type FormValueAllOfTwoAnyOf = {
  text: string
}

export type FormValueAllOfTwo = FormValueAllOfTwoAnyOf | FormValueAllOfTwoAnyOfTwo

export type FormValueAllOf = {
  publishedFormInputId: string
}

export type FormValue = FormValueAllOf & FormValueAllOfTwo

/**
 */
export type CreateSelectionAnswerEventInputType =
  (typeof CreateSelectionAnswerEventInputType)[keyof typeof CreateSelectionAnswerEventInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateSelectionAnswerEventInputType = {
  SELECTION_ANSWER: 'SELECTION_ANSWER',
} as const

export interface CreateSelectionAnswerEventInput {
  leadSessionId: string
  publishedSelectionOptionId: string
  /** */
  type: CreateSelectionAnswerEventInputType
}

/**
 */
export type CreateVideoViewingTimeEventInputType =
  (typeof CreateVideoViewingTimeEventInputType)[keyof typeof CreateVideoViewingTimeEventInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateVideoViewingTimeEventInputType = {
  VIDEO_VIEWING_TIME: 'VIDEO_VIEWING_TIME',
} as const

export interface CreateVideoViewingTimeEventInput {
  leadSessionId: string
  publishedSlideId: string
  /** */
  type: CreateVideoViewingTimeEventInputType
  viewingTime: number
}

/**
 */
export type CreateSlideViewEndEventInputType =
  (typeof CreateSlideViewEndEventInputType)[keyof typeof CreateSlideViewEndEventInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateSlideViewEndEventInputType = {
  SLIDE_VIEW_END: 'SLIDE_VIEW_END',
} as const

export interface CreateSlideViewEndEventInput {
  leadSessionId: string
  publishedSlideId: string
  /** */
  type: CreateSlideViewEndEventInputType
  viewingTime?: number
}

/**
 */
export type CreateSlideViewStartEventInputType =
  (typeof CreateSlideViewStartEventInputType)[keyof typeof CreateSlideViewStartEventInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateSlideViewStartEventInputType = {
  SLIDE_VIEW_START: 'SLIDE_VIEW_START',
} as const

export interface CreateSlideViewStartEventInput {
  leadSessionId: string
  publishedSlideId: string
  /** */
  type: CreateSlideViewStartEventInputType
}

export type CreateLeadSessionEventInput =
  | CreateSlideViewStartEventInput
  | CreateSlideViewEndEventInput
  | CreateVideoViewingTimeEventInput
  | CreateSelectionAnswerEventInput
  | CreateFormAnswerEventInput

/**
 * @nullable
 */
export type LeadSessionEventPublishedSlideId = Cuid | null

/**
 * @nullable
 */
export type LeadSessionEventPublishedSelectionOptionId = Cuid | null

export type LeadSessionEventType = (typeof LeadSessionEventType)[keyof typeof LeadSessionEventType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LeadSessionEventType = {
  SLIDE_VIEW_START: 'SLIDE_VIEW_START',
  SLIDE_VIEW_END: 'SLIDE_VIEW_END',
  SELECTION_ANSWER: 'SELECTION_ANSWER',
  FORM_ANSWER: 'FORM_ANSWER',
  VIDEO_VIEWING_TIME: 'VIDEO_VIEWING_TIME',
} as const

export interface LeadSessionEvent {
  createdAt: string
  id: Cuid
  leadSessionId: Cuid
  /** @nullable */
  publishedSelectionOptionId: LeadSessionEventPublishedSelectionOptionId
  /** @nullable */
  publishedSlideId: LeadSessionEventPublishedSlideId
  /** @nullable */
  text: string | null
  type: LeadSessionEventType
  updatedAt: string
  /** @nullable */
  viewingTime: number | null
}

export interface MergeLeadInput {
  destinationLeadId: string
}

export type LeadDetailAllOf = {
  answeredFormSlideIds: string[]
}

export interface Lead {
  createdAt: string
  id: Cuid
  updatedAt: string
}

export type LeadDetail = Lead & LeadDetailAllOf

/**
 * @nullable
 */
export type GetEmbedScriptOutputAllOfFloatConfig = EmbedScriptFloatConfig | null

export type GetEmbedScriptOutputAllOf = {
  /** @nullable */
  floatConfig: GetEmbedScriptOutputAllOfFloatConfig
}

export type GetEmbedScriptOutput = EmbedScript & GetEmbedScriptOutputAllOf

export type MobileDisplayType = (typeof MobileDisplayType)[keyof typeof MobileDisplayType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MobileDisplayType = {
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  SIDE_LEFT: 'SIDE_LEFT',
  SIDE_RIGHT: 'SIDE_RIGHT',
  CUSTOM_TOP_LEFT: 'CUSTOM_TOP_LEFT',
  CUSTOM_TOP_RIGHT: 'CUSTOM_TOP_RIGHT',
  CUSTOM_SIDE_LEFT: 'CUSTOM_SIDE_LEFT',
  CUSTOM_SIDE_RIGHT: 'CUSTOM_SIDE_RIGHT',
  CUSTOM_BOTTOM_LEFT: 'CUSTOM_BOTTOM_LEFT',
  CUSTOM_BOTTOM_RIGHT: 'CUSTOM_BOTTOM_RIGHT',
  NONE: 'NONE',
} as const

export type DesktopDisplayType = (typeof DesktopDisplayType)[keyof typeof DesktopDisplayType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DesktopDisplayType = {
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
} as const

export type Cuid = string

/**
 * @nullable
 */
export type EmbedScriptFloatConfigScenarioMobileThumbnailId = Cuid | null

export interface EmbedScriptFloatConfig {
  createdAt: string
  desktopDisplayType: DesktopDisplayType
  embedScriptId: Cuid
  id: Cuid
  mobileDisplayType: MobileDisplayType
  scenarioId: Cuid
  /** @nullable */
  scenarioMobileThumbnailId: EmbedScriptFloatConfigScenarioMobileThumbnailId
  updatedAt: string
}

export interface EmbedScript {
  createdAt: string
  id: Cuid
  updatedAt: string
}

export const getEmbedScript = (embedScriptId: Cuid) => {
  return getEmbedScriptMutator<GetEmbedScriptOutput>({ url: `/public/embed-scripts/${embedScriptId}`, method: 'POST' })
}

export const createLead = () => {
  return createLeadMutator<LeadDetail>({ url: `/public/leads`, method: 'POST' })
}

export const getLead = (leadId: Cuid) => {
  return getLeadMutator<LeadDetail>({ url: `/public/leads/${leadId}`, method: 'GET' })
}

export const setLeadProfileToLead = (leadId: Cuid, leadProfileId: Cuid) => {
  return setLeadProfileToLeadMutator<void>({
    url: `/public/leads/${leadId}/lead-profiles/${leadProfileId}`,
    method: 'PUT',
  })
}

export const mergeLead = (leadId: Cuid, mergeLeadInput: MergeLeadInput) => {
  return mergeLeadMutator<void>({
    url: `/public/leads/${leadId}/merge`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: mergeLeadInput,
  })
}

export const createLeadSessionEvent = (createLeadSessionEventInput: CreateLeadSessionEventInput) => {
  return createLeadSessionEventMutator<LeadSessionEvent>({
    url: `/public/lead-session-events`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createLeadSessionEventInput,
  })
}

export const createLeadSession = (createLeadSessionInput: CreateLeadSessionInput) => {
  return createLeadSessionMutator<LeadSession>({
    url: `/public/lead-sessions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createLeadSessionInput,
  })
}

export const getLatestPublishedScenario = (params: GetLatestPublishedScenarioParams) => {
  return getLatestPublishedScenarioMutator<PublishedScenarioDetail>({
    url: `/public/published-scenarios/latest`,
    method: 'GET',
    params,
  })
}

export const getScenarioMobileThumbnail = (scenarioMobileThumbnailId: string) => {
  return getScenarioMobileThumbnailMutator<WithUrlScenarioMobileThumbnail>({
    url: `/public/scenario-mobile-thumbnails/${scenarioMobileThumbnailId}`,
    method: 'GET',
  })
}

export type GetEmbedScriptResult = NonNullable<Awaited<ReturnType<typeof getEmbedScript>>>
export type CreateLeadResult = NonNullable<Awaited<ReturnType<typeof createLead>>>
export type GetLeadResult = NonNullable<Awaited<ReturnType<typeof getLead>>>
export type SetLeadProfileToLeadResult = NonNullable<Awaited<ReturnType<typeof setLeadProfileToLead>>>
export type MergeLeadResult = NonNullable<Awaited<ReturnType<typeof mergeLead>>>
export type CreateLeadSessionEventResult = NonNullable<Awaited<ReturnType<typeof createLeadSessionEvent>>>
export type CreateLeadSessionResult = NonNullable<Awaited<ReturnType<typeof createLeadSession>>>
export type GetLatestPublishedScenarioResult = NonNullable<Awaited<ReturnType<typeof getLatestPublishedScenario>>>
export type GetScenarioMobileThumbnailResult = NonNullable<Awaited<ReturnType<typeof getScenarioMobileThumbnail>>>
