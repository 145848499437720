import { Box, Center, Icon, useBoolean, VStack } from '@chakra-ui/react'
import { FC, useEffect, useRef } from 'react'
import { BsPlayCircle } from 'react-icons/bs'
import videoBg from '../../../../public/images/video_bg.webp'
import useHover from '../../../hooks/useHover'
import useIsMobile from '../../../hooks/useIsMobile'
import { PublishedVideoSlide, VideoStatus } from '../../../orval/loovPublic'
import UnderConstructionIcon from '../../elements/icons/UnderConstruction'
import Loading from '../../elements/Loading'
import H1 from '../../elements/typography/H1'
import useScenarioPlayer from './useScenarioPlayer'

export type VideoSlidePlayerPresentationProps = {
  slide: PublishedVideoSlide
}

const VideoSlidePlayerPresentation: FC<VideoSlidePlayerPresentationProps> = ({ slide }) => {
  const {
    currentTime,
    playbackRate,
    volume,
    replayEventTarget,
    setCurrentTime,
    setPlaybackRate,
    setVolume,
    goNext,
    onViewStart,
    recordViewingTime,
  } = useScenarioPlayer()
  const [isPlayButtonVisible, setIsPlayButtonVisible] = useBoolean()
  const [isVideoLoading, setIsVideoLoading] = useBoolean(true)
  const isMobile = useIsMobile()
  const isPausedRef = useRef(false)
  const videoElementRef = useRef<HTMLVideoElement>(null)
  const video = slide.publishedVideoSlideContent.video

  useEffect(() => {
    function replayHandler() {
      if (videoElementRef.current) {
        videoElementRef.current.currentTime = 0
        videoElementRef.current.play()
      }
    }
    // replay イベントにハンドラーを設定
    replayEventTarget.addEventListener('replay', replayHandler)
    return () => replayEventTarget.removeEventListener('replay', replayHandler)
  }, [replayEventTarget])

  useEffect(() => {
    const videoElement = videoElementRef.current
    if (!videoElement) return

    // 再生中にタブやアプリが切り替えられた場合は視聴時間を記録
    const visibilityChangeHandler = () => {
      if (!videoElement.paused && !videoElement.ended && document.visibilityState === 'hidden') {
        recordViewingTime()
      }
    }

    document.addEventListener('visibilitychange', visibilityChangeHandler)
    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler)

      // モーダルが閉じられるなど、再生中にアンマウントされた場合は視聴時間を記録
      if (!videoElement.paused && !videoElement.ended) {
        recordViewingTime()
      }
    }
  }, [recordViewingTime])

  return video && video.status === VideoStatus.AVAILABLE ? (
    <Box pos="relative" w="100%" aspectRatio="16/9" cursor="pointer">
      <video
        id="video"
        src={video.url + '#t=0.001'}
        controlsList="nodownload nofullscreen"
        disablePictureInPicture
        playsInline
        style={{
          ...(isVideoLoading && { display: 'none' }),
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          // ユーザーのWebサイトに埋め込まれる場合、絶対URLでは静的リソースを読み込めないので dataURL に変換して埋め込む
          backgroundImage: `url("${videoBg}")`,
          backgroundSize: 'cover',
        }}
        onTimeUpdate={(e) => setCurrentTime(e.currentTarget.currentTime)}
        onRateChange={(e) => setPlaybackRate(e.currentTarget.playbackRate)}
        onVolumeChange={(e) => setVolume(e.currentTarget.volume)}
        onPause={(e) => {
          isPausedRef.current = true
          if (!e.currentTarget.ended) {
            recordViewingTime()
          }
        }}
        onPlay={() => (isPausedRef.current = false)}
        onLoadedData={async (e) => {
          e.currentTarget.currentTime = currentTime
          e.currentTarget.playbackRate = playbackRate
          e.currentTarget.volume = volume

          // ローディング解除
          setIsVideoLoading.off()
          try {
            // autoplay
            await e.currentTarget.play()
            onViewStart()
          } catch (e) {
            // autoplay できない時は再生ボタンを表示する
            if (e instanceof Error && e.name === 'NotAllowedError') {
              return setIsPlayButtonVisible.on()
            }

            throw e
          }
        }}
        onEnded={() => goNext(slide.publishedVideoSlideContent)}
        {...(isMobile
          ? // モバイルでは、スライドが切り替わるたびにコントロールが表示されるとかなり鬱陶しいので、タップされるまでコントロールは表示しない
            {
              onClick: (e) => {
                const video = e.currentTarget
                if (video.controls === false && !isPlayButtonVisible) {
                  video.controls = true
                }
              },
            }
          : {
              controls: true,
              // デスクトップではクリックで再生/停止する
              // safari では、video をクリック時に video.paused を更新した後、onClick が先に呼ばれてから onPause/onPlay が呼ばれる
              // そのため、クリック直前の値を取得するには video.paused ではなく ref を参照する必要がある
              onClick: () => (isPausedRef.current ? videoElementRef.current?.play() : videoElementRef.current?.pause()),
            })}
        ref={videoElementRef}
      />

      {isVideoLoading && <Loading />}

      {isPlayButtonVisible && (
        <PlayButton
          onClick={async () => {
            await videoElementRef.current?.play()
            setIsPlayButtonVisible.off()
            onViewStart()
          }}
        />
      )}
    </Box>
  ) : (
    // 動画素材がない場合
    // 未設定のまま公開したり、公開後に動画素材を削除している可能性がある
    <VStack w="100%" h="100%" justify="center">
      <UnderConstructionIcon h="200px" w="200px" />
      <H1>ただいま準備中です。</H1>
    </VStack>
  )
}

type PlayButtonProps = { onClick: () => void }
const PlayButton: FC<PlayButtonProps> = ({ onClick }) => {
  const { isHovered, ref: hoverRef } = useHover<HTMLDivElement>()

  return (
    <Center
      pos="absolute"
      top={0}
      left={0}
      w="100%"
      h="100%"
      bg="rgba(0,0,0, 0.6)"
      onClick={() => onClick()}
      ref={hoverRef}
    >
      <Icon
        as={BsPlayCircle}
        pos="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        color="black.lightest"
        {...(!isHovered
          ? {
              boxSize: '50%',
              opacity: 0.8,
            }
          : {
              boxSize: '52%',
              opacity: 1,
            })}
      />
    </Center>
  )
}

export default VideoSlidePlayerPresentation
