import { Box, CloseButton, Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { assertNever } from '../../../commons/utils'
import Body from '../../elements/typography/Body'
import VisualMedia from '../../VisualMediaProps'

type EmbeddedDesktopFloatProps = {
  position: 'right' | 'left'
  description: string
  thumbnailSrc?: string
  showError?: boolean
  onReady: () => void
  onClick: () => void
  onClose: () => void
}
const EmbeddedDesktopFloat: FC<EmbeddedDesktopFloatProps> = (props) => {
  return (
    <Box
      pos="fixed"
      w="280px"
      p="16px"
      bottom="50px"
      {...(props.position === 'right'
        ? { right: '50px' }
        : props.position === 'left'
        ? { left: '50px' }
        : assertNever(props.position))}
      borderRadius="12px"
      bg="rgba(243,243,243,0.9)"
      shadow="dark-lg"
      _hover={{
        opacity: 0.85,
        bottom: 55,
      }}
      cursor="pointer"
      transition="all 0.2s ease-in-out"
      onClick={() => props.onClick()}
    >
      <CloseButton
        pos="absolute"
        fontSize="12px"
        boxSize="24px"
        top="0"
        right="0"
        transform="translate(50%, -50%)"
        borderWidth={1}
        borderColor="black.lighter"
        borderRadius="50%"
        bg="background"
        _hover={{ bg: 'black.lighter' }}
        onClick={(e) => (e.stopPropagation(), props.onClose())}
      />

      {/* 説明文 */}
      <Text fontSize="15px" m={0} mb="8px" align="center">
        {props.description}
      </Text>

      {/* サムネイル */}
      <Flex aspectRatio="16/9" minH={0} borderRadius={8} overflow="hidden">
        {props.thumbnailSrc ? (
          <VisualMedia
            src={props.thumbnailSrc}
            boxSize="full"
            objectFit="cover"
            onLoad={() => props.onReady()}
            onLoadedMetadata={() => props.onReady()}
            {...(props.showError && { onError: () => props.onReady() })}
          />
        ) : (
          <Body color="alert.basic" whiteSpace="pre-line">{`
            サムネイル画像が設定されていません。

            このメッセージは、プレビュー以外では表示されません。
            `}</Body>
        )}
      </Flex>
    </Box>
  )
}

export default EmbeddedDesktopFloat
