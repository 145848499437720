export const LocalStorageKey = {
  ACCESS_TOKEN: 'access-token',
  REFRESH_TOKEN: 'refresh-token',
  SWITCH_TENANT_ID: 'switch-tenant-id',
  LEAD_ID: 'lead-id',
  RECORDING_SPEAKER_TYPE: 'recording-speaker-type',
  TEXT_TO_SPEECH_VOICE_NAME: 'text-to-speach-voice-name',
  TEXT_TO_SPEECH_SPEAKING_RATE: 'text-to-speach-speaking-rate',
  TEXT_TO_SPEECH_PITCH: 'text-to-speach-pitch',
  TEXT_TO_SPEECH_VOLUME_GAIN_DB: 'text-to-speach-volume-gain-db',
} as const
type LocalStorageKey = (typeof LocalStorageKey)[keyof typeof LocalStorageKey]
