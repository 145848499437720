import { Box, CloseButton, Flex, Image, Stack, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { assertNever } from '../../../commons/utils'
import Body from '../../elements/typography/Body'

type EmbeddedMobileBottomFloatProps = {
  position: 'right' | 'left'
  description: string
  thumbnailSrc?: string
  showError?: boolean
  onReady: () => void
  onClick: () => void
  onClose: () => void
}
const EmbeddedMobileBottomFloat: FC<EmbeddedMobileBottomFloatProps> = (props) => {
  return (
    <Box
      pos="fixed"
      w="312px"
      p="4px"
      bottom="4px"
      {...(props.position === 'left'
        ? { left: '4px' }
        : props.position === 'right'
        ? { right: '4px' }
        : assertNever(props.position))}
      borderRadius="8px"
      bg="background"
      shadow="dark-lg"
      cursor="pointer"
      transition="all 0.2s ease-in-out"
      onClick={() => props.onClick()}
    >
      <CloseButton
        pos="absolute"
        fontSize="12px"
        boxSize="24px"
        top="0"
        right="0"
        transform="translate(0%, -50%)"
        borderWidth={1}
        borderColor="black.lighter"
        borderRadius="50%"
        bg="background"
        _hover={{ bg: 'black.lighter' }}
        onClick={(e) => (e.stopPropagation(), props.onClose())}
      />

      {/* 説明文 */}
      <Text fontSize="15px" m={0} mb="2px" align="center">
        {props.description}
      </Text>

      {/* サムネイル */}
      <Flex w="100%" aspectRatio="16/9" align="center">
        <Image
          src={props.thumbnailSrc ?? 'no thumbnail'}
          alt="thumbnail"
          w="100%"
          maxH="100%"
          borderRadius={8}
          fit="contain"
          onLoad={() => props.onReady()}
          fallbackStrategy="onError"
          fallback={
            <Stack>
              <Body color="alert.basic">サムネイル画像が設定されていません。</Body>
              <Body color="alert.basic">このメッセージは、プレビュー以外では表示されません。</Body>
            </Stack>
          }
          {...(props.showError && { onError: () => props.onReady() })}
        />
      </Flex>
    </Box>
  )
}

export default EmbeddedMobileBottomFloat
