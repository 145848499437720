import { FC } from 'react'

import FormSlidePlayer from './FormSlidePlayer'
import ScenarioPlayerPresentation from './ScenarioPlayerPresentation'
import SelectionSlidePlayer from './SelectionSlidePlayer'
import useScenarioPlayer from './useScenarioPlayer'
import VideoSlidePlayer from './VideoSlidePlayer'

const ScenarioPlayer: FC = () => {
  const props = useScenarioPlayer()

  return (
    <ScenarioPlayerPresentation
      {...props}
      VideoSlidePlayer={VideoSlidePlayer}
      SelectionSlidePlayer={SelectionSlidePlayer}
      FormSlidePlayer={FormSlidePlayer}
    />
  )
}

export default ScenarioPlayer
