import { Box, HStack, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Stack } from '@chakra-ui/react'
import { FC, RefObject } from 'react'
import ScenarioPlayer from './ScenarioPlayer'
import ScenarioPlayerGoBackButton from './ScenarioPlayerGoBackButton'
import ScenarioPlayerGoBackToBeginningButton from './ScenarioPlayerGoBackToBeginningButton'
import useScenarioPlayer from './useScenarioPlayer'

type ScenarioPlayerModalProps = {
  isOpen: boolean
  onClose: () => void
  containerRef?: RefObject<HTMLElement>
}
const ScenarioPlayerModal: FC<ScenarioPlayerModalProps> = (props) => {
  const { publishedScenario } = useScenarioPlayer()

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={() => props.onClose()}
      closeOnOverlayClick={false}
      isCentered
      portalProps={{ containerRef: props.containerRef }}
    >
      <ModalOverlay />
      <ModalContent
        as={Stack}
        spacing="8px"
        maxW={['none', '880px']}
        maxH={['80vh', 'none']}
        borderRadius="12px"
        bg="background"
        p={['8px', '16px']}
        // Chakraのモーダルがデフォルトでスクロールをブロックするようになってるので、回避する
        onWheel={(e) => e.stopPropagation()}
      >
        <ModalCloseButton
          fontSize="12px"
          boxSize="28px"
          top="0"
          right="0"
          transform={['translate(-20%, -120%)', 'translate(50%, -50%)']}
          bg="background"
          color="black.basic"
          _hover={{ bg: 'black.lighter' }}
        />

        <Box w="100%" aspectRatio={['auto', '16/9']} borderRadius="12px" overflow="auto" overscrollBehavior="contain">
          <ScenarioPlayer />
        </Box>

        {/* 戻るボタン */}
        {publishedScenario.isGoBackButtonEnabled && (
          <HStack spacing={4} justify={['space-between', 'normal']}>
            <ScenarioPlayerGoBackButton />
            <ScenarioPlayerGoBackToBeginningButton />
          </HStack>
        )}
      </ModalContent>
      <ModalFooter />
    </Modal>
  )
}

export default ScenarioPlayerModal
