import { FC } from 'react'
import SwrKey from '../../../commons/swrKey'
import useSuspenseSwr, { immutableOptions } from '../../../hooks/useSuspenseSwr'
import { DesktopDisplayType, getLatestPublishedScenario, MobileDisplayType } from '../../../orval/loovPublic'
import EmbeddedFloatingScenarioPlayerPresentation from './EmbeddedFloatingScenarioPlayerPresentation'

export type EmbeddedFloatingScenarioPlayerProps = {
  scenarioId: string
  desktopDisplayType: DesktopDisplayType
  mobileDisplayType: MobileDisplayType
  scenarioMobileThumbnailId: string | null
  preview?: boolean
}

const EmbeddedFloatingScenarioPlayer: FC<EmbeddedFloatingScenarioPlayerProps> = ({ scenarioId, ...props }) => {
  const { data: publishedScenario } = useSuspenseSwr(
    [SwrKey.PUBLISHED_SCENARIOS, { scenarioId }],
    () => getLatestPublishedScenario({ scenarioId }),
    immutableOptions,
  )

  return <EmbeddedFloatingScenarioPlayerPresentation publishedScenario={publishedScenario} {...props} />
}

export default EmbeddedFloatingScenarioPlayer
